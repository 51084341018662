<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">司書資格や司書課程について、また、調査についてコメント等がありましたらご自由にお書きください。<br/>【自由回答】</p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-textarea
					v-model="stateQ10"
					label=""
					:rules="[rules.max1000chars]"
					counter
					maxlength="1000"
					rows="5"
				></v-textarea>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -3.3em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -2.5em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurveyQ10',
	data () {
		return {
			rules: {
				required: value => !!value || '必須',
				max100chars: value => value.length <= 100 || '最大100字',
				max400chars: value => value.length <= 400 || '最大400字',
				max1000chars: value => value.length <= 1000 || '最大1000字',
			},
		}
	},
	computed: {
		...mapGetters({
			Q10: 'stateQ10',
		}),
		stateQ10: {
			get () {
				return this.Q10
			},
			set (value) {
				this.setQ10(value)
			}
		}
	},
	methods: {
		...mapActions([
			'setQ10',
		])
	}
}
</script>